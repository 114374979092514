export const BUCKETS = {
  MEDIA_BUCKET: "neufast-b2b-data-aia-test",
  ORIGINAL_VIDEO_BUCKET: "videoanalyticsprocess-aia-test",
  ORIGINAL_IMAGE_BUCKET:"imageanalyticsprocess-aia-test",
  ORIGINAL_AUDIO_BUCKET: "audioanalyticsprocess-aia-test",
  PROCESSED_MEDIA_BUCKET: "b2btarget-bucket-aia-test",
  RESUME_BUCKET: "neufast-b2b-data-aia-test",
  // RESUME_BUCKET: "neufast-resumes",
  DOWNLOAD_MULTIPLE_VIDEO_BUCKET: "neufast-zip-files-aia-test",
  EXCEL_DATA: "neufast-b2b-data-aia-test",
  RECRUITER_DATA: "recruiter-data-aia-test"
}
export const IMAGE_CAPTURE_INTERVAL = {
  MIN_INTERVAL_SECONDS: 5,
  MAX_INTERVAL_SECONDS: 10
}

export const SESSION_CHECKING_INTERVAL_IN_SEC = 7
export const SESSION_CONSTANTS = {
  // Inactivity Timer Constants
  INACTIVE_TIMEOUT_IN_SECOND: 60 * 30,
  REMAINING_TIME_DIALOG_POPUP_IN_SECOND: 30,
  INTERVAL_IN_MS: 1000,

  // Frontend session management constants
  DUPLICATE_SESSION_CHECKING_INTERVAL_IN_SEC: 7,
  DUPLICATE_SESSION_LOGOUT_IN_SEC: 5,
}

export const VideoInterviewModel = {
  FULL: "full",
  SIMPLIFIED: "simplified",
}


export const SIMPLIFIED_COMPETENCY = [
  "Task Performance",
  "Interpersonal relationship and Communication",
  "Adaptability",
]

export const Model_To_text = {
  "Leadership":"Recruiter_Stat_Filter_Leadership",
  "Developing People": "LayoutSettings_QuestionSubType_DevelopingPeople",
  "Goal and Achievement Striving":"Recruiter_Skills_GoalAndAchievementStriving",
  "Responsibility":"Recruiter_Skills_Responsibility",
  "Managing Execution":"Recruiter_Skills_ManagingExecution",
  "Analytical and Logical Thinking":"Recruiter_Skills_AnalyticalAndLogicalThinking",
  "Complex Problem Solving":"Recruiter_Skills_ComplexProblemSolving",
  "Vision and Entrepreneurial Mindset":"Recruiter_Skills_VisionAndEntrepreneurialMindset",
  "Technology Expertise and Business Sense":"Recruiter_Skills_TechnologyExpertiseAndBusinessSense",
  "Communication with Impact":"Recruiter_Skills_Communication",
  "Caring and Empathy":"Recruiter_Skills_CaringAndEmpathy",
  "Interpersonal relationship":"Recruiter_Stat_Filter_Interpersonal_Relationship",
  "Team Collaboration and Inclusion":"Recruiter_Skills_TeamCollaborationAndInclusion",
  "Building Relationships and Network":"Recruiter_Skills_BuildingRelationshipsAndNetwork",
  "Effective Communication and Teambuilding":"Recruiter_Skills_EffectiveCommunicationAndTeambuilding",
  "Flexibility and Adaptability":"Recruiter_Skills_FlexibilityAndAdaptability",
  "Learning Agility":"Recruiter_Stat_Filter_Learning_Agility",
  "Creativity and Innovation":"Recruiter_Stat_Filter_Creativity_And_Innovation",
  "Emotional Intelligence":"LayoutSettings_QuestionSubType_EmotionalIntelligence",
  "Grit and Passion":"Recruiter_Skills_GritAndPassion",
  "Task Performance":"LayoutSettings_QuestionSubType_TaskPerformance",
  "Interpersonal relationship and Communication":"LayoutSettings_QuestionSubType_InterpersonalRelationshipCommunication",
  "Adaptability":"LayoutSettings_QuestionSubType_Adaptability"
}

export const FULL_MODEL_COMPETENCY = [
  // "Impression Score",
  "Leadership",
  "Developing People",
  "Goal and Achievement Striving",
  "Responsibility",
  "Managing Execution",
  "Analytical and Logical Thinking",
  "Complex Problem Solving",
  "Vision and Entrepreneurial Mindset",
  "Technology Expertise and Business Sense",
  "Communication with Impact",
  "Caring and Empathy",
  "Interpersonal relationship",
  "Team Collaboration and Inclusion",
  "Building Relationships and Network",
  "Effective Communication and Teambuilding",
  "Flexibility and Adaptability",
  "Learning Agility",
  "Creativity and Innovation",
  "Emotional Intelligence",
  "Grit and Passion",

]

export const ROLE = {
  admin: 'admin',
  recruiter: 'recruiter',
  jobseeker: 'jobseeker',
  guest: 'guest',
};

export const INTERVIEW_STATUS = {
  SHORTLIST: 'Shortlisted',
  REJECT: 'Rejected',
  WAITING_LIST: 'Waiting_Listed',
  INTERVIEW_COMPLETE: 'Interview Complete'
}

export const COUNT_DOWN_LAYOUT = {
  default: 'default',
  digital: 'digital',
  circle: 'circle',
  boxed: 'boxed',
  pictureBoxed: 'pictureBoxed'
}

export const interviewLayout = {
  SIDE_BY_SIDE: "side-by-side",
  PICTURE_IN_PICTURE: "picture-in-picture",
};

export const STATUS = {
  success: 'success',
  fail: 'fail',
};

export const CHECK_ERROR = {
  completedInterview: 11,
  expiredInterview: 12,
  invalidCode: 13,
  unsupportedBrowser: 14,
  malfunctionedCamera: 15,
  malfunctionedMic: 16,
  unsuitableNetwork: 17,
}

export const LOGIN_METHOD = {
  normal: 'normal',
  viaUrl: 'viaUrl',
  actingAs: 'actingAs',
}

export const MOCK_RESPONSE_HEADER = {
  "content-type": "application/json",
  "access-control-allow-origin": "*",
  "Access-Control-Allow-Methods": "CONNECT, DELETE, GET, HEAD, OPTIONS, POST, PUT, PATCH, TRACE",
  "Access-Control-Allow-Headers": "Allow, Content-Encoding, Content-Length, Content-Type, ETag, Expires, Last-Modified, Location, Server, Vary",
  "Access-Control-Expose-Headers": "Allow, Content-Encoding, Content-Length, Content-Type, ETag, Expires, Last-Modified, Location, Server, Vary",
  "Access-Control-Max-Age": "300",
};

export const defaultTimeZone = [
  "Africa/Cote_d'Ivoire",
  "Africa/Ghana",
  "Africa/Addis_Ethiopia",
  "Africa/Algeria",
  "Africa/Eritrea",
  "Africa/Mali",
  "Africa/Central_African_Republic",
  "Africa/Gambia",
  "Africa/Guinea-Bissau",
  "Africa/Malawi",
  "Africa/Republic_of_Congo",
  "Africa/Democratic Republic of Congo/West Time",
  "Africa/Democratic Republic of Congo/Eastern Time",
  "Africa/Burundi",
  "Africa/Egypt",
  "Africa/Morocco",
  "Atlantic/Canary_Islands", //Spain
  "Africa/Guinea",
  "Africa/Senegal",
  "Africa/United_Republic_of_Tanzania",
  "Africa/Djibouti",
  "Africa/Cameroon",
  "Africa/Western_Sahara",
  "Africa/Sierra_Leone",
  "Africa/Botswana",
  "Africa/Zimbabwe",
  "Africa/South_Africa",
  "Africa/South_Sudan",
  "Africa/Uganda",
  "Africa/Sudan",
  "Africa/Rwanda",
  "Africa/Nigeria",
  "Africa/Gabon",
  "Africa/Togo",
  "Africa/Angola",
  "Africa/Zambia",
  "Africa/Equatorial_Guinea",
  "Africa/Mozambique",
  "Africa/Lesotho",
  "Africa/Swaziland",
  "Africa/Somalia",
  "Africa/Liberia",
  "Africa/Kenya",
  "Africa/Chad",
  "Africa/Niger",
  "Africa/Mauritania",
  "Africa/Burkina_Faso",
  "Africa/Benin",
  "Africa/Sao_Tome_and_Principe",
  "Africa/Libya",
  "Africa/Tunisia",
  "Africa/Namibia",
  "America/Hawaii-Aleutian Standard Time", //US 
  "America/Hawaii-Aleutian Daylight Time",
  "America/Alaska Standard Time", //US
  "America/Alaska Daylight Time",
  "America/Mountain Standard Time", //US, Mexico, Canada
  "America/Mountain Daylight Time",
  "America/Central Standard Time", //US, Mexico, Canada
  "America/Central Daylight Time",
  "America/Pacific Standard Time", //US, Mexico, Canada
  "America/Pacific Daylight Time",
  "America/Eastern Standard Time", //US, Canada
  "America/Eastern Daylight Time",
  "America/Newfoundland Standard Time", //Canada
  "America/Newfoundland Daylight Time",
  "America/Atlantic Standard Time", //Canada, Greenland
  "America/Atlantic Daylight Time",
  "America/Brazil/Acre Standard Time", //Brazil
  "America/Brazil/Amazon Standard Time", //Brazil
  "America/Brazil/Brasilia Standard Time", //Brazil
  "America/Brazil/Fernando de Noronha Standard Time", //Brazil
  "America/West Greenland Time",
  "America/East Greenland Time",
  "America/Anguilla",
  "America/Antigua_and_Barbuda",
  "America/Argentina",
  "America/Aruba",
  "America/Paraguay",
  "America/Barbados",
  "America/Belize",
  "America/Colombia",
  "America/Bolivarian_Republic_of_Venezuela",
  "America/French_Guiana",
  "America/Cayman_Islands",
  "America/Costa_Rica",
  "America/Curacao",
  "America/Dominica",
  "America/El_Salvador",
  "America/Turks_and_Caicos_Islands",
  "America/Grenada",
  "America/Guadeloupe",
  "America/Guatemala",
  "America/Ecuador/Galapagos Time", //Ecuador
  "America/Ecuador Time", //Ecuador
  "America/Guyana",
  "America/Cuba",
  "America/Jamaica",
  "America/Sint_Eustatius_and_Saba_Bonaire",
  "America/Bolivia",
  "America/Peru",
  "America/Sint_Maarten",
  "America/Nicaragua",
  "America/Saint_Martin",
  "America/Martinique",
  "America/Saint_Pierre_and_Miquelon",
  "America/Uruguay",
  "America/Montserrat",
  "America/Bahamas",
  "America/Panama",
  "America/Suriname",
  "America/Haiti",
  "America/Trinidad_and_Tobago",
  "America/Puerto_Rico",
  "America/Easter Island Standard Time", //Chile
  "America/Chile Standard Time", //Chile
  "America/Chile Summer Time", //Chile	
  "America/Dominican_Republic",
  "America/St_Barthelemy",
  "America/St_Kitts_and_Nevis",
  "America/St_Lucia",
  "America/US_Virgin_Islands", //Virgin Islands, US
  "America/St_Vincent_and_Grenadines",
  "America/Honduras",
  "America/British_Virgin_Islands",
  "Asia/Yemen",
  "Asia/Kazakhstan/East Time", //Kazakhstan
  "Asia/Kazakhstan/West Time", //Kazakhstan
  "Asia/Jordan",
  "Asia/Turkmenistan",
  "Asia/Iraq",
  "Asia/Bahrain",
  "Asia/Azerbaijan",
  "Asia/Thailand",
  "Asia/Lebanon",
  "Asia/Kyrgyzstan",
  "Asia/Brunei_Darussalam",
  "Asia/Mongolia/Choibalsan Time", //Mongolia 
  "Asia/Mongolia/Ulaanbaatar Time", //Mongolia
  "Asia/Mongolia/Hovd Time", //Mongolia 
  "Asia/China Standard Time",
  "Asia/Sri_Lanka",
  "Asia/Syrian_Arab_Republic",
  "Asia/Bangladesh",
  "Asia/Timor-Leste",
  "Asia/UAE",
  "Asia/Tajikistan",
  "Asia/Cyprus",
  "Asia/Palestine",
  "Asia/Vietnam",
  "Asia/Hong_Kong",
  "Asia/Russia/Irkutsk_Time", //Russia 
  "Asia/Russia/Kamchatka_Time", //Russia 
  "Asia/Russia/Krasnoyarsk_Time", //Russia 
  "Asia/Russia/Magadan_Time", //Russia 
  "Asia/Russia/Omsk_Time", //Russia  
  "Asia/Russia/Vladivostok_Time", //Russia 
  "Asia/Russia/Yakutsk_Time", //Russia 
  "Asia/Russia/Yekaterinburg_Time", //Russia 
  "Asia/Turkey",
  "Asia/Indonesian/Western Time", //Indonesia
  "Asia/Indonesian/Central Time", //Indonesia
  "Asia/Indonesian/Eastern Time", //Indonesia	
  "Asia/Israel",
  "Asia/Afghanistan",
  "Asia/Pakistan",
  "Asia/Nepal",
  "Asia/India",
  "Asia/Malaysia",
  "Asia/Kuwait",
  "Asia/Macau",
  "Asia/Philippines",
  "Asia/Oman",
  "Asia/Cambodia",
  "Asia/North_Korea",
  "Asia/Qatar",
  "Asia/Saudi_Arabia",
  "Asia/Uzbekistan",
  "Asia/South_Korea",
  "Asia/Singapore",
  "Asia/Taiwan",
  "Asia/Georgia",
  "Asia/Iran",
  "Asia/Bhutan",
  "Asia/Japan",
  "Asia/Laos",
  "Asia/Myanmar",
  "Asia/Armenia",
  "Europe/Netherlands",
  "Europe/Andorra",
  "Europe/Greece",
  "Europe/Serbia",
  "Europe/Germany",
  "Europe/Slovakia",
  "Europe/Belgium",
  "Europe/Romania",
  "Europe/Hungary",
  "Europe/Moldova",
  "Europe/Denmark",
  "Europe/Ireland",
  "Europe/Gibraltar",
  "Europe/Guernsey",
  "Europe/Finland",
  "Europe/Isle_of_Man",
  "Europe/Turkey",
  "Europe/Jersey",
  "Europe/Russia/Kaliningrad_Time", //Russia 
  "Europe/Russia/Moscow_Time", //Russia 
  "Europe/Russia/Samara_Time", //Russia 
  "Europe/Ukraine",
  "Europe/Portugal",
  "Atlantic/Portugal/Azores",
  "Europe/Slovenia",
  "Europe/United_Kingdom",
  "Europe/Luxembourg",
  "Europe/Spain",
  "Europe/Malta",
  "Europe/Åland_Islands",
  "Europe/Belarus",
  "Europe/France",
  "Europe/Cyprus",
  "Europe/Norway",
  "Europe/Montenegro",
  "Europe/Czech_Republic",
  "Europe/Latvia",
  "Europe/Italy",
  "Europe/San_Marino",
  "Europe/Bosnia_and_Herzegovina",
  "Europe/Macedonia",
  "Europe/Bulgaria",
  "Europe/Sweden",
  "Europe/Estonia",
  "Europe/Albania",
  "Europe/Moldova",
  "Europe/Liechtenstein",
  "Europe/Holy_See_Vatican",
  "Europe/Austria",
  "Europe/Lithuania",
  "Europe/Poland",
  "Europe/Croatia",
  "Europe/Switzerland",
  "Europe/Iceland",
  "Oceania/Australia/Western Standard Time", //Australia
  "Oceania/Australia/Central Western Standard Time", //Australia
  "Oceania/Australia/Central Standard Time", //Australia
  "Oceania/Australia/Eastern Standard Time", //Australia
  "Oceania/Australia/Lord Howe Standard Time", //Australia
  "Oceania/New_Zealand",
  "Oceania/New_Zealand/Chatham Island"
]
